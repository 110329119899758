body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selected button.page-link {
  background: #265999 !important;
  color: white;
}
.page-link {
  background: #26599929;
  color: black;
  padding: 8px 20px;
  font-weight: 700;
}
.page-link:hover {
  background: #3e70ac;
  color: white;
}

.active-class-pagination{
  /* background: hsl(214, 23%, 44%) !important; */
  background: #c0dcff !important;
}
